:root {
  --line: #5a6f7b;
}

.parent {
  padding: 0;
  display: flex;
  padding-top: 3vh;
  position: relative;
  justify-content: center;
}

.parent::before {
  content: '';
  top: 0vh;
  left: 50%;
  height: 3vh;
  position: absolute;
  border: solid 0.5px var(--line);
}

.parent:first-child::before {
  display: none;
}

.parent .child {
  display: flex;
  padding-top: 3vh;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.parent .child::before {
  content: '';
  top: 0;
  left: 50%;
  height: 3vh;
  position: absolute;
  border: solid 0.5px var(--line);
}

.parent .child::after {
  content: '';
  top: 0;
  width: 100%;
  position: absolute;
  border-top: solid 0.5px var(--line);
}

.parent .child:only-child::after {
  display: none;
}

.parent .child:first-of-type::after {
  width: 50%;
  right: 0;
}

.parent .child:last-of-type::after {
  width: 50%;
  left: 0;
}

.parent .child:only-child {
  padding-top: 0;
}

.parent .child:only-child::before {
  display: none;
}

.spouse::before {
  content: '';
  top: 23%;
  left: 54%;
  width: 20%;
  position: absolute;
  border-bottom: solid 0.5px var(--line);
  z-index: -10;
}

/* .spouse::after {
  content: '';
  bottom: 50;
  left: 500; 
  width: 50%;
  position: absolute;
  border-bottom: solid 0.5px #bdbdbd;
} */

/* .spouse::before {
  content: '';
  top: 0;
  left: -100%;
  height: 100%;
  position: absolute;
  border-right: solid 0.5px #bdbdbd;
} */

/* .spouse::before {
  content: '';
  top: 0;
  left: -100%;
  height: 100%;
  width: 10%;
  position: absolute;
  border-right: solid 0.5px #bdbdbd;
} */
